/*
 * Copyright 2018 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import '~bourbon/core/bourbon';

$spinner-wrapper-height: 175px;

@mixin hover-effect-for-top-menu {
  color:           $subnav-link-color;
  transition:      all 0.3s ease-in-out;
  text-decoration: none;
  &:hover {
    color:      $white-opaque;
    transition: all 0.3s ease-in-out;
  }
  &:visited {
    color: $subnav-link-color;
    &:hover {
      color:      $white-opaque;
      transition: all 0.3s ease-in-out;
    }
  }
  @media(min-width: $screen-md) {
    font-size:   13px;
    font-weight: 600;
  }
}

@mixin spinner-for-modal {
  min-height: $spinner-wrapper-height;
}

@mixin button($background-color) {
  display:       inline-block;
  padding:       0 20px;
  height:        $form-element-height;
  line-height:   $form-element-height;
  border-radius: $global-border-radius;
  font-size:     14px;
  font-weight:   400;
  cursor:        pointer;
  background:    $background-color;
  border:        1px solid $background-color;
  color:         $btn-text-color;
  transition:    $transition;

  &:active, &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.4;
    cursor:  not-allowed;
  }

  &:hover {
    background: darken($background-color, 10%);
    transition: $transition;
  }

  &:disabled {
    &:hover {
      background: $background-color;
      transition: $transition;
    }
  }
}
