/*
 * Copyright 2018 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

$headings-margin-bottom: $global-margin-bottom;
$headings-font-family: $font-family-base;
$headings-font-weight: $global-font-weight;
$headings-line-height: normal;
$headings-color: #333;

$h1-font-size: 17px;
$h2-font-size: 16px;
$h3-font-size: 15px;
$h4-font-size: 14px;
$h5-font-size: 13px;
$h6-font-size: 12px;

$hr-border-width: 1px;
$hr-border-color: #d5dddf;

// Headings

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-family:   $headings-font-family;
  font-weight:   $headings-font-weight;
  line-height:   $headings-line-height;
  color:         $headings-color;
}

h1, .h1 {
  font-size: $h1-font-size;
}

h2, .h2 {
  font-size: $h2-font-size;
}

h3, .h3 {
  font-size: $h3-font-size;
}

h4, .h4 {
  font-size: $h4-font-size;
}

h5, .h5 {
  font-size: $h5-font-size;
}

h6, .h6 {
  font-size: $h6-font-size;
}

hr {
  margin-top:    $global-margin-top;
  margin-bottom: $global-margin-bottom;
  border:        none;
  border-top:    $hr-border-width solid $hr-border-color;
}

.list-unstyled {
  padding-left: 0;
  list-style:   none;
}
