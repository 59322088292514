/*
 * Copyright 2018 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "~normalize-scss/sass/normalize/import-now";
@import '../views/global/common';
@import '../views/global/typography';

:global(.component-layout-body) {
  margin:                  0;
  font-family:             $font-family-base;
  font-size:               $font-size-base;
  font-weight:             $font-weight-base;
  line-height:             $line-height-base;
  color:                   $body-color;
  text-align:              left;
  background-color:        $body-bg;

  //for consistency with older pages:
  -webkit-font-smoothing:  antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@include font-face($font-family: 'FontAwesome',
                   $file-path: 'font-awesome/fonts/fontawesome-webfont',
                   $file-formats: ("woff2", "woff", "ttf", "svg", "eot")) {
  font-weight: normal;
  font-style:  normal;
}

$opensans-variants: (
                      Light: (
                        font-weight: 300,
                        font-style: normal,
                      ),
                      LightItalic: (
                        font-weight: 300,
                        font-style: italic,
                      ),
                      Regular: (
                        font-weight: normal,
                        font-style: normal,
                      ),
                      Italic: (
                        font-weight: normal,
                        font-style: italic,
                      ),
                      Semibold: (
                        font-weight: 600,
                        font-style: normal,
                      ),
                      SemiboldItalic: (
                        font-weight: 600,
                        font-style: italic,
                      ),
                      Bold: (
                        font-weight: bold,
                        font-style: normal,
                      ),
                      BoldItalic: (
                        font-weight: bold,
                        font-style: italic,
                      ),
                      ExtraBold: (
                        font-weight: 800,
                        font-style: normal,
                      ),
                      ExtraBoldItalic: (
                        font-weight: 800,
                        font-style: italic,
                      ),
                    );

@each $variant-name in map-keys($opensans-variants) {
  $variant: map-get($opensans-variants, $variant-name);
  @include font-face($font-family: 'Open Sans',
                     $file-path: 'opensans/OpenSans-#{$variant-name}-webfont',
                     $file-formats: ("eot", "woff", "ttf")
  ) {
    font-style:  map-get($variant, 'font-style');
    font-weight: map-get($variant, 'font-weight');
  }
}

.main-container {
  margin:      0 30px 50px;
  padding-top: 110px;
}

//sticky footer

html, body {
  height: 100%;
}

.page {
  height: 100%;
}

.pagewrap {
  min-height: 100%;
  @media(min-width: $screen-md) {
    margin-bottom: -94px;
  }
  @media(min-width: $screen-lg) {
    margin-bottom: -76px;
  }
}

.pagewrap:after {
  content: "";
  display: block;

}

.sitefooter, .pagewrap:after {
  @media(min-width: $screen-md) {
    height: 94px;
  }
  @media(min-width: $screen-lg) {
    height: 76px;
  }
}

